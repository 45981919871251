import { BaseWidget } from './base-widget';

export class Form extends BaseWidget {
    static NAME = 'form';

   constructor(element) {
        super(element);
    }

    init() {
        var current = $(window).scrollTop();
        var total = $(window).height() - current;
        var ele = $(".c-filter__figure");
        var currPosition = ele.position().left + 10;
        var trackLength = 100;

        $(window).scroll(function (event) {
            current = $(window).scrollTop();
            var newPosition = trackLength * (current/total)
            ele.css({left:currPosition+newPosition+'px'});
        });
    }
}
