import { Anchor } from './widgets/anchor';
import { Blog } from './widgets/blog';
import { Carousel } from './widgets/carousel';
import { Cursor } from './widgets/cursor';
// import { Dropdown } from './widgets/dropdown';
import { Faq } from './widgets/faq';
import { Footer } from './widgets/footer';
import { Form } from './widgets/form';
import { Gallery } from './widgets/gallery';
import { GravityForms } from './widgets/gravity-forms';
import { Header } from './widgets/header';
import { Hover } from './widgets/hover';
import { InView } from './widgets/in-view';
import { Menu } from './widgets/menu';
import { Lazyload } from './widgets/lazy-load';
import { Reveal } from './widgets/reveal';
import { ScrollTo } from './widgets/scroll-to';

// IE11 polyfill - If object-fit cover
// const pfObjectFitImages = require('object-fit-images');

// IE11 polyfill - If self hoasted video's
// const pfObjectFitVideos = require('object-fit-videos');

class App {
    constructor() {
        this.widgets = [];
        this.widgetMap = {
            [Anchor.NAME]: Anchor,
            [Blog.NAME]: Blog,
			[Cursor.NAME]: Cursor,
            [Faq.NAME]: Faq,
            [Footer.NAME]: Footer,
            [Gallery.NAME]: Gallery,
            [GravityForms.NAME]: GravityForms,
            [Header.NAME]: Header,
            [Hover.NAME]: Hover,
            [InView.NAME]: InView,
            [Menu.NAME]: Menu,
            [Lazyload.NAME]: Lazyload,
            [ScrollTo.NAME]: ScrollTo,
		};

        this.callOut();
        this.externalLinks();
        this.addEventListeners();
    }

    initWidgets($container) {
        $container = $container || $(document);

        for (const key in this.widgetMap) {
            const elements = $container[0].querySelectorAll('[data-'.concat(key).concat(']'));

            for (const element of elements) {
                this.attachWidgetToElement(element, key);
            }
        }

        if (location.hostname === 'localhost') {
            console.log(this.widgets);
        };
    }

    attachWidgetToElement(element, key) {
        for (const widget of this.widgets) {
            if (widget.element === element) {
                return;
            }
        }

        const newWidget = new this.widgetMap[key]( $(element) );
        newWidget.init();

        this.widgets.push(newWidget);
    }

    clearWidgets($container) {
        $container = $container || $(document);

        for (const key in this.widgetMap) {
            const elements = $container[0].querySelectorAll('[data-'.concat(key).concat(']'));

            for (const element of elements) {
                this.detachWidgetsFromElement(element);
            }
        }

        console.log('Current widgets: ', this.widgets);
    }

    detachWidgetsFromElement(element) {
        const filteredWidgets = [];

        for (const widget of this.widgets) {
            if (widget.element !== element) {
                filteredWidgets.push(widget);
            }
        }

        this.widgets = filteredWidgets;
    }

    addEventListeners() {
        this.initWidgets();
    }

    externalLinks() {
        // Add target _blank to external links
        $('a[href^="http"]:not([href*="' + location.host + '"])')
            .attr('target', '_blank')
            .attr('rel', 'noopener')
        ;
	}

    callOut() {
        console.log('Website gemaakt door Brthmrk')
    }
}

document.addEventListener('DOMContentLoaded', () => {
    require('intersection-observer');
    
    new App();

    $( document ).ready(function() {
        $('body').addClass('loaded');
    })

    if ('ontouchstart' in window || 'ontouch' in window ) {
        $('body').addClass('touch');
    } else {
        $('body').addClass('no-touch');
	}


    $(function() {

        $(".c-voorstelling__prices--toggle").on('click', function(e) {
            $(this).parent().toggleClass('is-active');
        });

        $(".c-voorstelling__credits--toggle").on('click', function(e) {
            $(this).parent().toggleClass('is-active');
        });

        $(".c-menu-overlay li.menu-item-has-children > a").on('click', function(e) {
            if ($(this).parent().is( ".is-open" )) {
                $(this).parent().removeClass("is-open");
            } else {
              e.preventDefault();
              $(this).parent().addClass("is-open");
            }
        });

        $(".c-menu-overlay li.menu-item-has-children .c-menu__chevron").on('click', function(e) {
            $(this).parent().toggleClass("is-open");
        });

        $(".c-imageheader__slider .c-imageheader__list").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            infinite: true,
            dots: true,
            arrows: false,
            pauseOnHover: false,
            autoplay: true,
            autoplaySpeed: 6000,
            fade: true,
            appendDots: $(".c-imageheader__dots-inner")
        });

        $(".c-hero__list").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            infinite: true,
            dots: true,
            arrows: false,
            pauseOnHover: false,
            autoplay: true,
            autoplaySpeed: 6000,
            fade: true,
            appendDots: $(".c-hero__dots-inner")
        });

        jQuery(".c-carousel__list").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            mobileFirst: true,
            dots: false,
            arrows: true,
            pauseOnHover: true,
            autoplay: false,
            fade: false,
            prevArrow: ".c-carousel__nav .c-carousel__button-prev",
            nextArrow: ".c-carousel__nav .c-carousel__button-next",
            responsive: [
              {
                breakpoint: 568,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                }
              }
            ]
        });

        // $(".c-gallery-slider__main").slick({
        //     mobileFirst: true,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     responsive: [
        //         {
        //           breakpoint: 1024,
        //           settings: {
        //             slidesToShow: 5,
        //             slidesToScroll: 1,
        //             infinite: true,
        //           }
        //         },
        //         {
        //           breakpoint: 600,
        //           settings: {
        //             slidesToShow: 3,
        //             slidesToScroll: 1
        //           }
        //         },
        //         {
        //           breakpoint: 480,
        //           settings: {
        //             slidesToShow: 2,
        //             slidesToScroll: 1
        //           }
        //         }
        //     ],
        //     centerMode: false,
        //     infinite: true,
        //     dots: false,
        //     arrows: true,
        //     pauseOnHover: false,
        //     autoplay: false,
        //     fade: false,
        //     prevArrow: $(".c-gallery-slider__arrow-prev"),
        //     nextArrow: $(".c-gallery-slider__arrow-next")
        // });

        $(".c-selected-events-slider__main").slick({
            mobileFirst: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: false,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ],
            centerMode: false,
            infinite: false,
            dots: false,
            arrows: true,
            pauseOnHover: false,
            autoplay: false,
            fade: false,
            prevArrow: $(".c-selected-events-slider__arrow-prev"),
            nextArrow: $(".c-selected-events-slider__arrow-next")
        });
    });

});
