import 'slick-carousel';
import { BaseWidget } from './base-widget';

export class Carousel extends BaseWidget {
    static NAME = 'carousel';

    constructor(element) {
        super(element);
    }

    init() { 
        $(".c-gallery__main").slick({
            infinite: true,
            slidesToShow: 1,
            arrows: false,
            slidesToScroll: 1,
            mobileFirst: true,
            dots: false,
            autoplay: false,
            fade: false,
            arrows: false,
            asNavFor: '.c-gallery__attachment'
        });

        $('.c-gallery__attachment').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.c-gallery__main',
            dots: false,
            arrows: true,
            centerMode: false
        });

        $(".c-gallery-slider__main").slick({
            mobileFirst: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ],
            centerMode: false,
            infinite: true,
            dots: false,
            arrows: true,
            pauseOnHover: false,
            autoplay: false,
            fade: false,
            prevArrow: $(".c-gallery-slider__arrow-prev"),
            nextArrow: $(".c-gallery-slider__arrow-next")
        });
    
    }
}
