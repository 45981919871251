import 'slick-carousel';
import { BaseWidget } from './base-widget';

export class Gallery extends BaseWidget {
    static NAME = 'gallery';

    constructor(element) {
        super(element);
    }

    init() {
        jQuery(function() {
            $('.c-gallery-slider__main').slick({
                centerMode: true,
                centerPadding: '30px',
                slidesToShow: 1,
                arrows: false,
                dots: true,
                mobileFirst: true,
                responsive: [
                    {
                      breakpoint: 1024,
                      settings: {
                        arrows: true,
                        centerPadding: '100px',
                        prevArrow: $(".c-gallery-slider__arrow-prev"),
                        nextArrow: $(".c-gallery-slider__arrow-next"),
                      }
                    }
                ]
            });

            $('.c-video__button').on('click', function(e) {
                e.preventDefault();
                $("#ytiframe")[0].src += "?autoplay=1";
                $('#ytiframe').show();
                $('.c-video__image').hide();
                $('.c-video__button').hide();
            })
        });
    }
}
